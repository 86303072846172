@import '~@devsta/ui-kit/scss/core/vars';
@import '~@devsta/ui-kit/scss/core/mixins';
@import '../mixins/login-form-mixin';

.login-form {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 10px;
  position: relative;
  text-align: center;

  &__body {
    @include login-form-mixin;

    @media only screen and (max-width: $media-sizes-small) {
      background-color: transparent;

      &__field {
        p {
          color: $white;
        }
      }
    }

    > .login-header {
      @media only screen and (max-width: $media-sizes-small) {
        color: $white;
      }
    }

    &__account {
      font-size: $font-sizes-small;
      margin-bottom: 0;
      margin-top: 50px;
    }

    &__form {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      width: 100%;
    }

    &__forgot-btn {
      font-size: $font-sizes-small;
      font-weight: $font-weights-bold;
      text-transform: uppercase;
    }

    &__error {
      align-items: center;
      display: flex;

      @media only screen and (max-width: $media-sizes-small) {
        color: $black;
        flex-direction: column;
        white-space: normal;
      }

      > p {
        font-size: $font-sizes-small;
        margin-bottom: 0;
        margin-left: 10px;

        @media only screen and (max-width: $media-sizes-small) {
          margin: 0;
        }
      }
    }
  }

  &__footer {
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    margin-bottom: 20px;
    padding-left: 30px;
    position: absolute;
    width: 100%;

    > p {
      color: $regent;
      font-size: 12px;
      font-weight: $font-weights-medium;
      margin-bottom: 0;
    }

    @media only screen and (max-width: $media-sizes-small) {
      justify-content: center;
      padding-left: 0;

      > p {
        color: $white;
      }

      > svg {
        path {
          fill: $white;
        }
      }
    }

    &__logo {
      @include icon-colour(#737781);

      margin-left: 5px;
      margin-top: -1px;
    }
  }
}
