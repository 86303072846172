@import '~@devsta/ui-kit/scss/core/vars';

.help-button {
  color: $aqua !important;
  font-size: $font-sizes-small;
  margin-right: 40px !important;
  position: relative;
  text-decoration: underline;

  &:hover {
    color: $monkey;
  }

  &::after {
    background: $russian;
    content: '';
    height: 100%;
    position: absolute;
    right: -20px;
    width: 1px;
  }
}
