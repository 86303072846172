@import '~@devsta/ui-kit/scss/core/vars';

.login-header {
  color: $black;
  margin-bottom: 45px;

  > .icon-container {
    margin-bottom: 25px;
  }

  > h1 {
    margin-bottom: 0;
  }

  > h2 {
    font-size: 20px;
    font-weight: $font-weights-regular;
    margin-bottom: 0;
  }
}
