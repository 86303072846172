@import '~@devsta/ui-kit/scss/core/vars';

.user-summary {
  background: $white;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(140, 150, 169, 0.5);
  cursor: default;
  padding: 25px;
  position: absolute;
  transform: translate(0%, 60px);

  &--hidden {
    display: none;
  }

  &__container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;

    &__usr-info {
      display: flex;

      > .image {
        background-size: cover !important;
        margin-right: 15px;
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;

        > h4 {
          color: $black;
          font-size: $font-sizes-default;
          font-weight: $font-weights-medium;
          letter-spacing: 1px;
          line-height: 20px;
          margin-bottom: 0;
          text-transform: uppercase;
        }

        > h5 {
          color: $black;
          font-size: 10px;
          font-weight: $font-weights-regular;
          letter-spacing: 1px;
          line-height: 15px;
        }
      }
    }

    &__nav {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin-bottom: 0;
      margin-left: 0;
      padding-top: 21px;
      white-space: nowrap;

      &__link {
        color: $black !important;
        font-size: $font-sizes-small;
        font-weight: $font-weights-medium;
        letter-spacing: 1px;
        line-height: 17px;
        margin-bottom: 6px;
        padding-bottom: 0;

        &--sign-out {
          color: $red-apple !important;
          margin-top: 20px !important;
        }

        &:hover {
          color: $black;
        }
      }
    }
  }

  &__horizontal-rule {
    height: 1px;
    margin: 0;
    opacity: 0.5;
    padding-top: 20px;
    width: 100%;
  }

  &__organisation-info {
    display: flex;
    margin-top: 20px;

    &__description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 15px;

      &__name {
        font-size: $font-sizes-small;
        letter-spacing: 1px;
        line-height: 17px;
        margin: 0;
      }

      &__team {
        font-size: $font-sizes-micro;
        letter-spacing: 1px;
        line-height: 15px;
        margin: 0;
      }
    }
  }
}
