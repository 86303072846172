@import '~@devsta/ui-kit/scss/core/vars';

.notifications-wrapper {
  display: flex;
  height: 100%;
  justify-content: center;
  margin-right: 30px;
  padding-top: 1px;

  &__notification-box {
    cursor: pointer;
    display: flex;

    &__icon {
      //margin-right: 30px;
    }
  }
}
