@import '~@devsta/ui-kit/scss/core/vars';

.user-wrapper-container {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-top: 1px;

  &__userprofile-box {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &__icon {
      align-items: flex-start;
      display: flex;
      margin-left: 10px;

      &__ghost {
        margin-right: 20px;
      }
    }

    > .image {
      margin-right: 10px;
    }

    > h6 {
      align-self: center;
      color: $black;
      font-size: 12px;
      font-weight: $font-weights-medium;
      letter-spacing: 1px;
      line-height: 17px;
      margin-bottom: 0;
      text-transform: uppercase;
      user-select: none;
      white-space: nowrap;
    }
  }
}
