@import '~@devsta/ui-kit/scss/core/vars';
@import '~@devsta/ui-kit/scss/core/mixins';

.login {
  height: 100%;

  @media only screen and (max-width: $media-sizes-small) {
    background: $monkey;
    color: $white;
  }

  &__side-panel {
    background: $monkey;
    height: 100%;

    > .icon-container {
      display: inline-block;
      margin: 30px;
    }
  }

  &__container {
    height: 100%;
  }

  &__right-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    text-align: center;
  }
}
