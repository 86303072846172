@import '~@devsta/ui-kit/scss/core/vars';
@import '~@devsta/ui-kit/scss/core/mixins';

.reauthenticate {
  &__modal {
    &__content {
      align-items: center;
      display: flex;
      flex-direction: column;

      &__error {
        align-items: center;
        color: $thunder-bird;
        display: flex;
        margin-top: 20px;
  
        > p {
          font-size: $font-sizes-small;
          margin-bottom: 0;
          margin-left: 10px;
        }
      }

      &__form {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        > p {
          font-size: $font-sizes-small;
          font-weight: $font-weights-medium;
          margin-top: 20px;
        }

        > form {
          margin-bottom: 30px;
          width: 100%;
        }

        > button:last-of-type {
          font-size: $font-sizes-small;
          margin-top: 20px;
        }
      }

      &__profile {
        align-items: center;
        display: flex;

        &__text {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          &__name {
            font-weight: $font-weights-medium;
          }

          &__email {
            font-size: $font-sizes-small;
          }
        }
      }
    }
  }
}
