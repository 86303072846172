@import '~@devsta/ui-kit/scss/core/typography';
@import '~@devsta/ui-kit/scss/core/vars';

.view-all-notifications {
  &__header {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    &__title {
      color: $black;
      font-size: 30px;
      letter-spacing: 2px;
      line-height: 35px;
      margin-bottom: 25px;
      text-align: center;
    }

    &__indicator {
      align-items: center;
      background-color: $monkey;
      border-radius: 50%;
      color: $white;
      height: 16px;
      margin: -60px 0 -15px -5px;
      width: 16px;

      &__text {
        align-items: center;
        display: flex;
        font-size: 8px;
        height: 100%;
        justify-content: center;
        width: 100%;
      }
    }
  }

  &__mark-read {
    color: $black;
    float: right;
    font-size: 12px;
    font-weight: $font-weights-bold;
    margin-top: 20px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  &__content {
    background-color: #f9f9fb;
    padding: 10px 75px 50px;
  }

  &__section {
    margin: 50px 0 0;

    &__title {
      margin: 15px 0;
    }

    .unread {
      background-color: #f2f4f8;
    }

    &__item {
      background-color: #fff;
      border-radius: 5px;
      margin: 10px 0;

      &__content {
        align-items: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: left;
        width: 100%;

        &__text {
          display: flex;
          font-size: $font-sizes-micro;
          line-height: 17px;
          padding: 10px;
          width: 100%;

          span {
            align-items: center;
            display: flex;
            justify-content: left;
            padding: 5px;

            > a {
              display: block;
              margin-bottom: 5px;
              margin-top: 5px;
            }

            strong {
              color: #000;
              margin: 0 5px;
              text-decoration: underline;
            }
          }
        }

        &__updated {
          display: flex;
          font-size: $font-sizes-micro;
          margin: 0;
          opacity: 0.3;
          padding: 10px 0 10px 20px;
          width: 100px;
        }
      }
    }

    &__no-items {
      font-size: $font-sizes-small;
      height: 100%;
      padding: 30% 0;
      text-align: center;
    }
  }
}
