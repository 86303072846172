@import '~@devsta/ui-kit/scss/core/vars';

header {
  background-color: $white;
  height: 60px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;

  .global-header-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding-top: 1px;
  }
}
