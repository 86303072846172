@import '~@devsta/ui-kit/scss/core/vars';

@mixin login-form-mixin() {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 10px;
  position: relative;
  width: 100%;

  &__close-btn {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  @media only screen and (max-width: $media-sizes-small) {
    background-color: $white;

    &__close-btn {
      right: 10px;
      top: 10px;
    }
  }

  &__field {
    display: block;
    max-width: 600px;
    width: 50% !important;

    @media only screen and (max-width: $media-sizes-medium) {
      width: 75% !important;
    }

    @media only screen and (max-width: $media-sizes-small) {
      width: 100% !important;
    }

    &:first-of-type {
      margin-bottom: 20px;
    }
  }

  &__submit {
    margin-top: 50px;
  }
}
