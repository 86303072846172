@import '~@devsta/ui-kit/scss/core/vars';

.notifications-summary {
  background: $white;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(140, 150, 169, 0.5);
  cursor: default;
  position: absolute;
  transform: translate(0%, 60px);
  width: 300px;

  &--hidden {
    display: none;
  }

  &__items {
    max-height: 40vh;
    overflow-y: auto;
  }

  &__horizontal-rule {
    height: 1px;
    margin: 0;
    opacity: 0.5;
    width: 100%;
  }

  &__view-all-button {
    margin: 40px auto 20px;
    width: 80%;
  }

  &__indicator {
    align-items: center;
    background-color: $monkey;
    border-radius: 50%;
    color: #fff;
    display: flex;
    height: 16px;
    justify-content: center;
    position: absolute;
    transform: translate(8px, 13px);
    width: 16px;

    &__text {
      align-items: center;
      display: flex;
      font-size: 8px;
      height: 100%;
      justify-content: center;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    font-size: $font-sizes-micro;
    justify-content: space-around;
    margin-left: -10px;
    margin-right: -10px;
    padding: 15px 0;

    &__mark-read {
      color: $black;
      font-weight: $font-weights-bold;
      text-decoration: underline;
      text-transform: uppercase;
    }
  }

  .unread {
    background-color: #f2f4f8;
  }

  &__item {
    &__content {
      &__text {
        display: flex;
        font-size: $font-sizes-micro;
        line-height: 17px;
        margin: 0;
        padding: 15px 15px 0;

        span {
          padding: 5px;

          > a {
            display: block;
            margin-bottom: 5px;
            margin-top: 5px;
          }

          strong {
            color: #000;
            text-decoration: underline;
          }
        }
      }

      &__updated {
        font-size: $font-sizes-micro;
        margin: 0;
        opacity: 0.3;
        padding: 0 0 10px 20px;
      }
    }
  }

  &__no-items {
    font-size: $font-sizes-micro;
    padding: 50px 30px 15px;
    text-align: center;
  }
}
